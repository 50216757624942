<template>
  <el-form :model="form" :rules="rules" ref="form" label-width="100px">
    <!-- 商品图片 -->
    <el-form-item label="商品图片" prop="imageUrls">
      <div style="display: flex">
        <div
          v-for="(item, index) in form.imageUrls"
          :key="index"
          class="shoplistimg"
          draggable="true"
          @dragstart="dragStart($event, index, item)"
          @drop="drop($event, index, item)"
        >
          <i class="el-icon-delete-solid delicon" @click="delimg(index)"></i>
          <el-image
            fit="cover"
            style="width: 148px; height: 148px; margin-right: 4px"
            :src="item"
          ></el-image>
        </div>
        <el-upload
          class="avatar-uploader"
          :action="uploadUrl"
          name="files"
          list-type="picture-card"
          :before-upload="beforeupload"
          :on-success="onUploadSuccess"
          :on-error="onUploadError"
          :on-exceed="whenUploadOverLimitNum"
          :multiple="false"
          :headers="headers"
          :show-file-list="false"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </div>

      <div class="tips" style="margin-top: 5px">
        最多可上传5张，首张为商品缩略图，在列表中显示
      </div>
    </el-form-item>
    <!-- 商品名称 -->
    <el-form-item label="商品名称" prop="productName">
      <el-input
        v-model="form.productName"
        maxlength="30"
        placeholder="请输入商品名称"
      >
      </el-input>
      <span>{{ form.productName.length }}/30</span>
    </el-form-item>
    <el-form-item label="商品分类" prop="productTypeId">
      <el-cascader
        :options="goodsClassify"
        v-model="form.productTypeId"
        clearable
      ></el-cascader>
    </el-form-item>
    <!-- 选择专区 -->
    <el-form-item label="选择专区" prop="isOne">
      <div>
        <el-radio v-model="form.isOne" :label="2">普通商品</el-radio>
      </div>
      <div class="tips" v-show="form.isOne == 1">
        *一号专区商品，平台会收取15%手续费，用户购买时可获得5倍兑礼券。
      </div>
    </el-form-item>
    <!-- 商品规格 -->
    <el-form-item label="商品规格" prop="goodsSpecFormat">
      <div class="sepc-box">
        <div>
          <el-button @click="onAddSpecBtn">新增规格</el-button>
        </div>
        <div
          class="f-fs-fs-c"
          v-for="(specItem, ind) in form.goodsSpecFormat"
          :key="ind"
        >
          <!-- 规格名 -->
          <div class="sepc-box-list f-sb-c">
            <el-input
              v-model="specItem.spec_name"
              placeholder="请输入规格名称（例如：颜色，尺码，重量等）"
            ></el-input>
            <i class="el-icon-delete sepc-box-icon" @click="delsku(ind)"></i>
          </div>

          <!-- 规格值 -->
          <div
            class="sepc-box-list f-sb-c"
            v-for="(it, i) in specItem.value"
            :key="i"
          >
            <el-input
              v-model="it.spec_value_name"
              placeholder="请输入规格值（例如：红色，S码，5斤等）"
            ></el-input>
            <i
              class="el-icon-minus sepc-box-icon f-c-c"
              @click="delval(ind, i)"
            ></i>
          </div>
          <el-button @click="onAddSpecValBtn(ind)" type="primary"
            >新增规格值</el-button
          >
        </div>

        <div>
          <el-button @click="onCreateSpecsTab" type="warning"
            >生成规格表</el-button
          >
        </div>

        <div class="specstab-box">
          <table
            class="specstab"
            :style="'width:' + tableTitle.length * 142 + 'px;'"
            cellspacing="0px"
          >
            <thead>
              <tr>
                <td v-for="(item, index) in tableTitle" :key="index">
                  {{ item }}
                </td>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, index) in tableContent" :key="index">
                <!-- 这里的数据是合并行的 -->
                <td v-for="(item2, index2) in item" :key="index2">
                  <span v-if="item2.type == '规格'">{{ item2.value }}</span>
                  <el-input
                    v-if="item2.type == '价格'"
                    oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                    :maxlength="6"
                    v-model="item2.value"
                  ></el-input>
                  <el-input
                    v-if="item2.type == '市场价'"
                    oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                    :maxlength="6"
                    v-model="item2.value"
                  ></el-input>

                  <el-input
                    v-if="item2.type == '库存'"
                    oninput="value=(value.replace(/\D/g,'')==''?'':value>=100000?100000:parseInt(value))"
                    v-model="item2.value"
                    :validate-event="true"
                  ></el-input>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </el-form-item>
    <!-- 配送方式 -->
    <el-form-item
      label="配送方式"
      prop="deliveryType"
      style="position: relative; z-index: 0"
    >
      <el-checkbox-group v-model="form.deliveryType">
        <el-checkbox label="2" name="deliveryType">物流</el-checkbox>
        <el-checkbox label="1" name="deliveryType">自提</el-checkbox>
        <el-checkbox label="3" name="deliveryType">配送</el-checkbox>
      </el-checkbox-group>
    </el-form-item>
    <!--开售状态  -->
    <el-form-item label="开售状态" prop="canBuy">
      <el-radio-group v-model="form.canBuy">
        <el-radio :label="1">正式开售</el-radio>
        <el-radio :label="0">暂不开售</el-radio>
      </el-radio-group>
    </el-form-item>
    <!-- 商品详情 -->
    <el-form-item
      label="商品详情"
      prop="deproductDetailtail"
      style="position: relative; z-index: 0"
    >
      <BaseEditor
        ref="getHtml"
        :height="800"
        :html.sync="form.productDetail"
        placeholder="请输入商品详情"
      />
    </el-form-item>
    <el-form-item style="position: relative; z-index: 0">
      <el-button type="primary" @click="onSubmit()">发布</el-button>
      <el-button @click="$refs.form.resetFields(onreset())">重置</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
export default {
  data() {
    return {
      indexStart: null, //拖拽下标
      indexEnd: null, //拖拽下标
      productId: '', //需要编辑的商品id
      title: '', //规格名称
      list: [], //表中生成前的数据
      tableTitle: [], //表头
      tableContent: [], //生成后表中的数据
      editorHtml: '',
      editorText: '',
      quota: 0, //限购
      headers: {
        Authorization: localStorage.getItem('token'),
      },
      uploadUrl: process.env.VUE_APP_API + '/api/common/fileInfo/newUpload',
      fileList: [], //图片列表
      //商品分类
      goodsClassify: [],
      imgs: [],
      specs: {
        head: ['颜色', '尺码', '单价', '市场价', '库存'], //["颜色", "尺码", "单价", "库存"]
        body: ['color', 'size', 'price', 'personalPrice', 'stock'], //["color", "size", "price", "stock"]
        list: [],
      },
      form: {
        deliveryType: [], //配送方式：1自提2物流3配送
        productDetail: '', //商品详情，富文本数据
        goodsSpecFormat: [], //规格详情
        imageUrls: [], //图片地址
        isOne: 2, //是否一号店商品：1是 2不是
        productName: '', //商品名称
        productTypeId: [], //商品类型id
        productSpecs: [], //库存详情
        shopId: '', //店铺id
        // salesVirtual:0, //虚拟销量
        canBuy: 1,
      },
      rules: {
        deliveryType: [
          {
            required: true,
            message: '请输入',
          },
        ],

        productDetail: [
          {
            required: true,
            message: '请输入',
          },
        ],
        goodsSpecFormat: [
          {
            required: true,
            message: '请输入',
          },
        ],
        imageUrls: [
          {
            required: true,
            message: '请输入',
          },
        ],
        isOne: [
          {
            required: true,
            message: '请输入',
          },
        ],
        name: [
          {
            required: true,
            message: '商品名称不能为空',
          },
        ],
        productTypeId: [
          {
            required: true,
            message: '请输入',
          },
        ],
        productSpecs: [
          {
            required: true,
            message: '请输入',
          },
        ],
        shopId: [
          {
            required: true,
            message: '请输入',
          },
        ],
        canBuy: [
          {
            required: true,
            message: '请选择',
          },
        ],
      },
    };
  },
  created() {
    let that = this;
    // 编辑时
    if (this.$route.query.productId) {
      this.productId = this.$route.query.productId;
      this.getSpecInfoById(this.productId);
    }
    this.getGoodsClassify();
  },
  methods: {
    //获取商品信息
    getSpecInfoById(e) {
      this.$api.goods.detailByProductId({ id: e }).then((res) => {
        if (res.success) {
          this.form = res.data;
          this.form.goodsSpecFormat = JSON.parse(this.form.goodsSpecFormat);
          this.form.deliveryType = this.form.deliveryType.split(',');

          if (this.form.productDetail) {
            this.$refs.getHtml.setHtml(this.form.productDetail);
          }

          this.onCreateSpecsTab();
        } else {
          this.$message({
            message: res.message,
            type: 'success',
          });
        }
      });
    },

    //获取商品分类信息
    getGoodsClassify() {
      this.$api.goods.getClassify().then((res) => {
        this.goodsClassify = res.data.map((item) => {
          item.label = item.classify.name;
          item.value = item.classify.id;
          item.children.forEach((it) => {
            it.label = it.name;
            it.value = it.id;
            return it;
          });
          return item;
        });
      });
    },
    //删除当前轮播图片
    delimg(ind) {
      this.form.imageUrls.splice(ind, 1);
    },
    // 上传文件之前的钩子
    beforeupload(file) {
      if (this.form.imageUrls.length >= 5) {
        this.$message({
          message: '最多添加五张图片',
          type: 'warning',
        });

        return false;
      } else {
        const isJPG = file.type == 'image/png' || file.type == 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isJPG && isLt2M;
      }
    },
    //当上传图片成功时
    onUploadSuccess(res, file, fileList) {
      if (this.form.imageUrls.length < 5) {
        this.form.imageUrls.push(res.data[0].fileUrl);
      } else {
        this.$message({
          message: '最多添加五张图片',
          type: 'warning',
        });
      }
    },
    //当上传失败时
    onUploadError(err, file, fileList) {
      console.log('上传图片失败：', err);
    },
    //生成规格表
    onCreateSpecsTab() {
      let specItems = this.form.goodsSpecFormat || [];
      let getspecItemIDArr = this.form.productSpecs;

      getspecItemIDArr.forEach((item, index) => {
        specItems[0].value.specId = item.specId || '';
      });

      // 生成表头
      this.list = []; //表中生成前的数据
      this.tableTitle = []; //表头
      this.tableContent = []; //生成后表中的数据
      let ind = '';

      this.list = specItems.map((item) => {
        if (ind == '') {
          ind = 0;
        } else {
          ind++;
        }

        let alls = item.value.map((item1) => {
          return {
            specId: item1.specId || '',
            value: item1.spec_value_name,
          };
        });

        return {
          sort: ind,
          new: false,
          title: item.spec_name,
          list: alls,
        };
      });

      //计算 笛卡尔积
      if (!this.list.length) return false;
      let flag = false;

      flag = this.list.some((item) => {
        return item.list.length;
      });

      if (!flag) return false;
      this.handleTableHead();
      this.handleTableData();
    },

    // 生成表头
    handleTableHead() {
      this.tableTitle = [];
      this.list.forEach((item) => {
        if (item.list.length) {
          this.tableTitle.push(item.title);
        }
      });

      this.tableTitle.push('价格', '市场价', '库存');
    },

    // 处理表格中的数据
    handleTableData(num) {
      const listData = this.list.filter((item) => item.list.length); //去掉没有值的规格
      if (this.tableContent.length) {
        // 表格中数据不是第一次添加时
        let tempList = []; //用于存放新加的规格和值
        let tempContentList = this.tableContent.length; //记录上一次数据的总长度

        listData.forEach((item, index) => {
          item.list.forEach((item2, index2) => {
            if (item2.new) {
              //新添加的值
              let flag = false; // flag用于判断新值有没有添加进tempList
              let count = -1;
              flag = tempList.some((sitem, sindex) => {
                count++;
                return sitem.title == item.title;
              });
              if (flag) {
                //如果添加过则直接在里面的list追加
                tempList[count].list.push(item2);
              } else {
                //没添加过就添加一个新的对象
                tempList.push({
                  title: item.title,
                  list: [item2],
                  new: item.new,
                  sort: item.sort,
                });
              }
            }
          });
        });
        tempList.forEach((item) => {
          const list = [];
          // list代表所有新值的规格和其他规格匹配的数据
          if (item.new && item.list.length == 1) {
            // 如果新加的规格的值只有一个，那么直接在每一列加新值
            for (let i = 0; i < tempContentList; i++) {
              this.tableContent[i].splice(item.sort, 0, {
                type: '规格',
                value: item.list[0].value,
                sort: item.sort,
                key: item.title,
              });
            }
          } else {
            // 如果新加的规格的值大于一个，那么先在每一列插入新值
            // 然后再拿其他值互相匹配
            if (item.new && item.list.length > 1) {
              for (let i = 0; i < tempContentList; i++) {
                this.tableContent[i].splice(item.sort, 0, {
                  type: '规格',
                  value: item.list[0].value,
                  sort: item.sort,
                  key: item.title,
                });
              }
              // 第一个值已经加进去，所以要删掉
              item.list.splice(0, 1);
            }
            // 先把新加的值加进去
            list.push(item);
            listData.forEach((listItem) => {
              listItem.list.forEach((listItem2) => {
                item.list.forEach((item2) => {
                  if (listItem2.value == item2.value) {
                    // 上面的新值已经加进去所以要在原来的listData中做标记
                    // 避免下面出现重复的组合
                    listItem2.disabled = true;
                  }
                });
              });
            });
            listData.forEach((listItem) => {
              if (listItem.title !== item.title) {
                // 深拷贝避免影响原来的数据
                let tempListItem = JSON.parse(JSON.stringify(listItem));
                for (let count = 0; count < tempListItem.list.length; count++) {
                  if (tempListItem.list[count].disabled) {
                    //已经加过的值删掉
                    tempListItem.list.splice(count, 1);
                    count--;
                  }
                }
                list.push(tempListItem); // 加进list组合成新的数据
              }
            });
            list.sort((n1, n2) => n1.sort - n2.sort); //排序 避免新加的表中数据和表头对应不上

            this.transformData(list);
          }
        });
        // 生成后把所有的值初始化 用于下次生成判断新值
        this.list.forEach((item) => {
          item.new = false;
          item.list.forEach((item2) => {
            item2.new = false;
            item2.disabled = false;
          });
        });
        return false;
      }
      this.transformData(listData);
    },

    transformData(list) {
      console.log(this.tableContent, 'tableContent');
      const arr = [];
      // 这里改造一下数据
      list.forEach((item) => {
        const tempList = [];
        item.list.forEach((item2) => {
          let obj = {
            type: '规格',
            value: item2.value,
            sort: item.sort,
            key: item.title,
          };
          tempList.push(obj);
        });
        arr.push(tempList);
      });
      // 再改造成真正的表中数据类型
      const data = this.cartesianProductOf(...arr);

      // 这里给表中每行加一些写死的值，如输入框 单选按钮等
      data.forEach((item) => {
        if (this.form.productSpecs.length > 0) {
          let types = ''; //用于规格名组合
          item.forEach((item1) => {
            if (item1.type == '规格') {
              if (types == '') {
                types = item1.value;
              } else {
                types = types + ';' + item1.value;
              }
            }
          });

          let val = true; //用于判断新增字段规格
          item.forEach((item1) => {
            this.form.productSpecs.forEach((key) => {
              if (key.specName == types && val) {
                console.log('1111893');
                val = false;
                item.push({
                  type: '价格',
                  value: key.price,
                  rowspan: 1,
                });
                item.push({
                  type: '市场价',
                  value: key.personalPrice,
                  rowspan: 1,
                });
                item.push({
                  type: '库存',
                  value: key.count,
                  rowspan: 1,
                });
                item.push({
                  type: 'ID',
                  value: key.specId || '',
                  rowspan: 1,
                });
              }
            });
          });
          if (val) {
            item.push({
              type: '价格',
              value: '',
              rowspan: 1,
            });
            item.push({
              type: '市场价',
              value: '',
              rowspan: 1,
            });
            item.push({
              type: '库存',
              value: '',
              rowspan: 1,
            });
            item.push({
              type: 'ID',
              value: '',
              rowspan: 1,
            });
          }
        } else {
          item.push({
            type: '价格',
            value: '',
            rowspan: 1,
          });
          item.push({
            type: '市场价',
            value: '',
            rowspan: 1,
          });
          item.push({
            type: '库存',
            value: '',
            rowspan: 1,
          });
        }
      });

      this.tableContent.push(...data);
    },
    // 笛卡尔积
    cartesianProductOf() {
      return Array.prototype.reduce.call(
        arguments,
        function (a, b) {
          var ret = [];
          a.forEach(function (a) {
            b.forEach(function (b) {
              ret.push(a.concat(b));
              // ret.push([...a, ...b]);
            });
          });
          return ret;
        },
        [[]]
      );
    },

    //

    //当上传图片的数量超出限制数量时
    whenUploadOverLimitNum() {
      this.$message.warning('最多只能上传5张图片');
    },
    //当点击新增规格按钮时
    onAddSpecBtn() {
      if (this.form.goodsSpecFormat.length < 2) {
        this.form.goodsSpecFormat.push({
          spec_name: '',
          spec_Id: '',
          value: [
            {
              specId: '',
              specName: '',
            },
          ],
        });
      } else {
        this.$message({
          message: '最多只能设置两个规格名',
          type: 'warning',
        });
      }
    },
    //当点击新增规格值按钮时
    onAddSpecValBtn(ind) {
      this.form.goodsSpecFormat[ind].value.push({
        specId: '',
        specName: '',
      });
    },

    //删除当前规格值  js刪除数组
    delval(ind, i) {
      let list = this.form.goodsSpecFormat;
      list[ind].value.splice(i, 1);
      this.form.goodsSpecFormat = list;
    },

    //删除当前规格
    delsku(ind) {
      let list = this.form.goodsSpecFormat;
      list.splice(ind, 1);
      this.form.goodsSpecFormat = list;
    },

    //重设
    onreset() {
      this.list = []; //表中生成前的数据
      this.tableTitle = []; //表头
      this.tableContent = []; //生成后表中的数据
    },

    isPriceNumber(_keyword) {
      if (
        _keyword == '0' ||
        _keyword == '0.' ||
        _keyword == '0.0' ||
        _keyword == '0.00'
      ) {
        _keyword = '0';
        return true;
      } else {
        var index = _keyword.indexOf('0');
        var length = _keyword.length;
        if (index == 0 && length > 1) {
          /*0开头的数字串*/
          var reg = /^[0]{1}[.]{1}[0-9]{1,2}$/;
          if (!reg.test(_keyword)) {
            return false;
          } else {
            return true;
          }
        } else {
          /*非0开头的数字*/
          var reg = /^[1-9]{1}[0-9]{0,10}[.]{0,1}[0-9]{0,2}$/;
          if (!reg.test(_keyword)) {
            return false;
          } else {
            return true;
          }
        }
        return false;
      }
    },

    //发布
    onSubmit() {
      //不要删除此处注释
      //传参数的格式
      // let params={
      //   deliveryType: "1,3,2"
      //   detail: "<p>连连看</p>"
      //   goodsSpecFormat: "[{'spec_name':'尺码','spec_Id':2278,'type':'select','value':[{'spec_value_Id':10807,'spec_value_name':'1'},{'spec_value_Id':10808,'spec_value_name':'2'}]}]"
      //   imageUrl: "https://image.soole.com.cn/c292f03d-b3dd-43d3-82b9-9f1bd3e9477a,https://image.soole.com.cn/c292f03d-b3dd-43d3-82b9-9f1bd3e9477a"
      //   isOne: 2
      //   name: "大师傅"
      //   productTypeId: 643
      //   repository List: "[{'productName':'1','price':'1.00','count':1,'goodsSpecFormat':[{'spec_value_name':'1','spec_value_Id':10807}]},{'productName':'2','price':'1.00','count':1,'goodsSpecFormat':[{'spec_value_name':'2','spec_value_Id':10808}]}]"
      //   shopId: 1252818
      // }

      // 生成 goodsSpecFormat

      let lock = '';
      let tableContent = this.tableContent;

      let listarr = [];
      let all = {
        specName: '',
        price: '',
        personalPrice: '',
        count: '',
        specId: '',
        goodsSpecFormat: [],
      };
      console.log(tableContent, 'tableContent791');
      tableContent.forEach((item, index) => {
        let count = 0; //计数count为1单规格否则为多规格

        item.forEach((item1, index1) => {
          if (item1.type == '规格') {
            count++;
            if (item1.value == '') {
              lock = '规格名不能为空,请重新生成表格';
            }

            if (count == 1) {
              all.specName = item1.value;
            } else {
              all.specName = all.specName + ';' + item1.value;
            }
          } else if (item1.type == '价格') {
            item1.value = String(item1.value);
            if (this.isPriceNumber(item1.value) == false) {
              lock = '规格价格格式错误';
            }
            all.price = item1.value;
          } else if (item1.type == '库存') {
            const result = /^\d+$/.test(item1.value);
            if (!result) {
              lock = '库存只能是数字';
            }
            all.count = item1.value;
          } else if (item1.type == '市场价') {
            item1.value = String(item1.value);
            if (this.isPriceNumber(item1.value) == false) {
              lock = '市场价格式错误';
            }
            all.personalPrice = item1.value;
          } else if (item1.type == 'ID') {
            all.specId = item1.value || '';
          }
        });

        listarr.push({ ...all });
      });
      if (!listarr.length) {
        lock = '商品规格请生成规格表！';
      }
      let params = {
        ...this.form,
        shopId: this.$store.state.user.shopId,
      };

      params.deliveryType = params.deliveryType.toString();
      //   params.imageUrls = params.imageUrls.toString();
      params.goodsSpecFormat = JSON.stringify(params.goodsSpecFormat);

      if (this.productId == '') {
        params.productTypeId = params.productTypeId[1];
      } else if (typeof params.productTypeId == 'object') {
        params.productTypeId = params.productTypeId[1];
      }
      params.productSpecs = listarr;

      //获取富文本
      params.productDetail = this.$refs.getHtml.getHtml();
      this.form.productDetail = params.productDetail;

      if (lock != '') {
        this.$message({
          message: lock,
          type: 'warning',
        });
        return;
      }

      params.salesVirtual = 9999;
      params.salesModeId = 7;
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.productId) {
            let productId = this.productId;
            delete params.imageList;
            this.$api.goods
              .updateProductRelease({
                ...params,
                productId: productId,
              })
              .then((res) => {
                if (res.code == 200) {
                  this.$message({
                    message: res.message,
                    type: 'success',
                  });
                  this.$router.push({ path: '/manage/goods/list', query: {} });
                } else {
                  this.$message({
                    message: res.message,
                    type: 'error',
                  });
                }
              });
          } else {
            //发布
            this.$api.goods
              .publish({ ...params })
              .then((res) => {
                if (res.code == 200) {
                  this.$message({
                    message: res.message,
                    type: 'success',
                  });
                  this.$router.push({ path: '/manage/goods/list', query: {} });
                } else {
                  this.$message({
                    message: res.message,
                    type: 'error',
                  });
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        } else {
          this.$message.warning('请按提示信息正确填写内容！');
        }
      });
    },

    //拖拽图片
    dragStart(e, index, filed) {
      this.indexStart = index;
    },
    drop(e, index, filed) {
      this.indexEnd = index;
      let x = this.indexStart + 1;
      let y = index + 1;
      this.form.imageUrls.splice(
        x - 1,
        1,
        ...this.form.imageUrls.splice(y - 1, 1, this.form.imageUrls[x - 1])
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.el-input,
.el-cascader {
  width: 400px;
}
.upload-imgs {
  color: #999999;
  width: 60px;
  height: 60px;
  border: 1px solid #bbbbbb;
  border-radius: 6px;
  font-size: 30px;
}
.tips {
  font-size: 14px;
  line-height: 1.5;
}
.sepc-box {
  .el-button {
    margin: 0;
    margin-bottom: 10px;
  }

  .sepc-box-list {
    margin-bottom: 10px;
  }
  .sepc-box-icon {
    cursor: pointer;
    width: 36px;
    height: 36px;
    margin-left: 10px;
    border-radius: 50%;
    font-size: 20px;
    background-color: #ff5000;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
  }
}

.shoplistimg {
  position: relative;
}
.delicon {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  color: #f35555;
  position: absolute;
  padding: 2px;
  z-index: 9;
  right: 4px;
  top: 0px;
  font-size: 22px;
}
//替换
.replaceicon {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  color: #f35555;
  position: absolute;
  padding: 2px;
  z-index: 9;
  right: 40px;
  top: 0px;
  font-size: 22px;
}

.specstab-box {
  overflow: auto;
  width: 100%;
}
.specstab {
  width: auto;
}
.specstab thead {
  background-color: #ebeef5;
}
.specstab .el-input {
  width: 100%;
}
.specstab td {
  text-align: center;
  width: 140px;
}
.specstab tbody td {
  border: 1px solid #f1f1f1;
}
.specstab tbody td .el-input__inner {
  border: none;
}
</style>
